<template>
  <app-layout class="bg-white">
    <div class="container mx-auto">
      <app-table 
        :apiResponse="data"
        :showNumber="false">
        <template v-slot:header>
          <th class="font-bold">{{ $t("admin.cron.name") }}</th>
          <th class="font-bold">{{ $t("admin.cron.action") }}</th>
        </template>
        <template v-slot:body="slotData">
          <td width="90%">
            {{ slotData.model.name }}
          </td>
          <td width="10%">
            <app-button-outline
              class="bg-white"
              padding="px-3 py-1"
              :loading="loading && slotData.model.id === indexClicked"
              @click="cronItemClicked(slotData.model)"
            >
              <app-icon-outline
                v-if="!(loading && slotData.model.id === indexClicked)"
                name="TerminalIcon"
                class="h-5 w-5 mr-3 text-gray-400"
              />
              <p class="text-xs">
                {{ $t("admin.cron.run_cron") }}
              </p>
            </app-button-outline>
          </td>
        </template>
      </app-table>
    </div>
  </app-layout>
</template>

<script>
export default {
  data() {
    return {
      indexClicked: null,
    };
  },
  computed: {
    data() {
      return {
        data: [
          {
            id: 1,
            name: this.$t("admin.cron.settlement"),
          },
          {
            id: 2,
            name: this.$t("admin.cron.payout"),
          },
          {
            id: 3,
            name: this.$t("admin.cron.email_reminder"),
          },
        ],
      };
    },
    loading() {
      return this.$store.getters["paymentStore/loading"];
    },
  },
  methods: {
    async cronItemClicked(model) {
      this.indexClicked = model.id;
      switch (model.name) {
        case this.$t("admin.cron.settlement"):
          await this.postSettlement();        
          break;
          case this.$t("admin.cron.payout"):
          await this.postPayout();        
          break;
          case this.$t("admin.cron.email_reminder"):
          await this.postEmailReminder();
          break;
      }
    },
    async postPayout() {
      let cronPayout = await this.$store.dispatch("paymentStore/cronPayout");
      if (this.$lodash.isEmpty(cronPayout.errors)) {
        this.$notify(
          {
            group: "success",
            title: this.$t("message.send_successful"),
          },
          8000
        );
      }
    },
    async postSettlement() {
      let cronSettlement = await this.$store.dispatch(
        "paymentStore/cronSettlement"
      );

      if (this.$lodash.isEmpty(cronSettlement.errors)) {
        this.$notify(
          {
            group: "success",
            title: this.$t("message.send_successful"),
          },
          8000
        );
      }
    },
    async postEmailReminder() {
      let cronEmailReminder = await this.$store.dispatch(
        "paymentStore/cronEmailReminder"
      );

      if (this.$lodash.isEmpty(cronEmailReminder.errors)) {
        this.$notify(
          {
            group: "success",
            title: this.$t("message.send_successful"),
          },
          8000
        );
      }
    },
  },
};
</script>
